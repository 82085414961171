<template>
  <TheModal :show="users_notif" @close="users_notif = false">
    <div class="p-5 min-w-max truncate">
      <div class="flex place-content-between items-center mb-5">
        <div class="text-xl leading-7 font-semibold text-gray-900">
          {{ $t("add_users_notif") }}
        </div>
        <XIcon
          class="h-4 cursor-pointer text-gray-400"
          @click="users_notif = false"
        />
      </div>
    </div>
  </TheModal>
  <TheCard class="w-full mt-3 h-fit min-h-fit">
    <TheCardHeader
      class="flex text-sm leading-5 font-medium overflow-hidden mb-4 border-b border-gray-300 w-full"
      style="padding: 0px"
    >
      <div
        class="w-fit p-6 place-content-center flex cursor-pointer"
        :class="
          selected_role === 'curator'
            ? 'selected_content_types_list'
            : 'not_selected_content_types_list'
        "
        @click="selected_role = 'curator'"
      >
        {{ $t("tutors") }}
      </div>
      <div
        class="w-fit p-6 place-content-center flex cursor-pointer"
        :class="
          selected_role === 'student'
            ? 'selected_content_types_list'
            : 'not_selected_content_types_list'
        "
        @click="selected_role = 'student'"
      >
        {{ $t("students") }}
      </div>
    </TheCardHeader>
    <div v-if="users.length == 0 && !addMode" class="w-full">
      <UserGroupIcon class="h-8 text-gray-400 mx-auto" />
      <p class="text-xl text-gray-800 text-center mt-3">
        {{ $t("add_users", $t(`selected_role_${no}`)) }}
      </p>
      <p class="text-md text-gray-500 text-center">
        {{ $t("no_users_add_users", $t(`selected_role_${no}`)) }}
      </p>
    </div>
    <TheTable v-if="!addMode && users.length > 0">
      <TheTableHead>
        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{
          $t("name")
        }}</TheTableHeadCell>
        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{
          $t("contacts")
        }}</TheTableHeadCell>
        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{
          $t("added_date")
        }}</TheTableHeadCell>
        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6"></TheTableHeadCell>
      </TheTableHead>
      <TheTableBody>
        <TheTableRow
          v-for="user in users"
          :key="`user_course_group_${user.id}`"
        >
          <TheTableRowCell class="pl-4 pr-3 sm:pl-6">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <UserAvatar
                  class="rounded-full flex-shrink-0"
                  :url="user.user.avatar"
                />
              </div>
              <div class="ml-4">
                <div class="font-medium text-gray-900">
                  {{ user.user.full_name }}
                </div>
              </div>
            </div>
          </TheTableRowCell>
          <TheTableRowCell
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          >
            <div>
              <div class="flex text-gray-500">
                <MailIcon class="h-5 mr-2" />
                {{ user.user.email }}
              </div>
              <div class="flex text-gray-500">
                <PhoneIcon class="h-5 mr-2" />
                {{ user.user.phone_number }}
              </div>
            </div>
          </TheTableRowCell>
          <TheTableRowCell>
            {{ formatDate(user.started_at) }}
          </TheTableRowCell>
          <TheTableRowCell class="dropdown">
            <DotsVerticalIcon
              class="h-5 cursor-pointer my-3"
              @click="user.is_dropdown_open = !user.is_dropdown_open"
            />
            <div v-if="user.is_dropdown_open" class="dropdown-content">
              <a class="ml-2 mr-2 cursor-pointer" @click="deleteUser(user)">{{
                $t("delete")
              }}</a>
            </div>
          </TheTableRowCell>
        </TheTableRow>
      </TheTableBody>
    </TheTable>
    <div v-else-if="addMode">
      <div class="flex h-fit place-content-between gap-3 mb-4">
        <input
          type="file"
          :ref="`file`"
          class="hidden"
          accept=".xls,.xlsx"
          id="users_file"
          @change="uploadFile"
        />
        <div class="grid grid-cols-2 w-full flex-wrap">
          <div class="flex gap-2">
            <TheButton
              bg="outline"
              class="text-gray-500 h-full w-fit"
              @click="$refs['file'].click()"
            >
              <UploadIcon class="h-5 mr-2" />
              {{ $t("upload_from_file") }}
            </TheButton>
            <TheButton bg="outline" class="text-gray-500 h-full w-fit">
              <a href="/example.xlsx" download class="flex w-fit">
                <DownloadIcon class="h-5 mr-2" />
                {{ $t("download_template") }}
              </a>
            </TheButton>
          </div>
          <div class="flex h-fit place-content-end gap-3">
            <InputText
              :placeholder="$t('search_by_name_email')"
              v-model="search_new_users"
              class="h-full"
              style="font-size: initial !important"
            >
              <template #leftIcon>
                <SearchIcon class="h-5 text-gray-400" />
              </template>
            </InputText>
            <TheButton
              v-if="addMode"
              @click="addNewUsers"
              bg="primary"
              class="float-right"
            >
              {{ $t("add_to_group") }}
            </TheButton>
          </div>
        </div>
      </div>
      <div v-if="users.length == 0" class="w-full">
        <UserGroupIcon class="h-8 text-gray-400 mx-auto" />
        <p class="text-xl text-gray-800 text-center mt-3">
          {{ $t("add_users", $t(`selected_role_${no}`)) }}
        </p>
        <p class="text-md text-gray-500 text-center">
          {{ $t("no_users_add_users", $t(`selected_role_${no}`)) }}
        </p>
      </div>
      <TheTable v-if="users.length > 0">
        <TheTableHead>
          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">
            <InputCheckbox
              v-model="selectAll"
              @change="addAllToNewUsers"
              htmlId="selectAll"
            />
          </TheTableHeadCell>
          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{
            $t("name")
          }}</TheTableHeadCell>
          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{
            $t("contacts")
          }}</TheTableHeadCell>
        </TheTableHead>
        <TheTableBody>
          <TheTableRow
            v-for="user in users"
            :key="`user_course_group_${user.id}`"
          >
            <TheTableRowCell class="pl-4 sm:pl-6">
              <InputCheckbox
                class="checkbox"
                :htmlId="`user_${user.id}`"
                v-model="user.selected"
                @change="addToNewUsers($event, user.id)"
              />
            </TheTableRowCell>
            <TheTableRowCell class="pl-4 pr-3 sm:pl-6">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <UserAvatar
                    class="rounded-full flex-shrink-0"
                    :url="user.avatar"
                  />
                </div>
                <div class="ml-4">
                  <div class="font-medium text-gray-900">
                    {{ user.full_name }}
                  </div>
                </div>
              </div>
            </TheTableRowCell>
            <TheTableRowCell
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
            >
              <div>
                <div class="flex text-gray-500">
                  <MailIcon class="h-5 mr-2" />
                  {{ user.email }}
                </div>
                <div class="flex text-gray-500">
                  <PhoneIcon class="h-5 mr-2" />
                  {{ user.phone_number }}
                </div>
              </div>
            </TheTableRowCell>
          </TheTableRow>
        </TheTableBody>
      </TheTable>
      <div ref="sentinel" class="sentinel"></div>
    </div>
  </TheCard>
  <TheButton
    v-if="position"
    class="fixed right-6"
    :class="
      $router.currentRoute._value.name === 'GroupForm'
        ? 'bottom-20'
        : 'bottom-2'
    "
    @click="scrollToTop"
    >{{ $t("back_to_top") }}</TheButton
  >
</template>
<script>
import GroupCardMixin from "./GroupCardMixin";
export default {
  mixins: [GroupCardMixin],
  mounted() {
    window.addEventListener("scroll", this.checkIfNearEnd);
  },
};
</script>
<style scoped lang="postcss">
.selected_content_types_list {
  @apply text-gray-900 border-b-2 border-indigo-700 text-gray-700;
}
.not_selected_content_types_list {
  @apply text-gray-500;
}
.divider {
  border-width: 1px;
  @apply w-0 border-gray-200;
}
.float-right {
  float: right;
}
.min-h-fit {
  min-height: fit-content;
}
.m-auto {
  margin: auto;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  left: -50px;
  top: 40px;
  z-index: 1;
  padding: 4px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
</style>
