<template>
  <div>
    <div class="flex flex-row mb-20">
      <div class="w-3/5">
        <div class="text-2xl leading-8 font-semibold mb-5">{{ $t(type) }}</div>
        <TheCard v-if="currentPage === 1 && type === 'lesson'">
          <input
            class="border-none text-lg leading-8 font-semibold text-gray-900 shadow-none w-full focus:outline-none"
            :placeholder="$t('resource_name')"
            v-model="form.name"
          />
          <!-- <input
              class="border-none text-base leading-6 font-normal text-gray-900 shadow-none w-full  focus:outline-none"
              :placeholder="$t('description')"
              v-model="form.description"

          /> -->
        </TheCard>
        <TheCard v-if="currentPage === 1 && type === 'practice'">
          <input
            class="border-none text-lg leading-8 font-semibold text-gray-900 shadow-none w-full focus:outline-none"
            :placeholder="$t('resource_name')"
            v-model="form.name"
          />
          <!-- <input
              class="border-none text-base leading-6 font-normal text-gray-900 shadow-none w-full  focus:outline-none"
              :placeholder="$t('description')"
              v-model="form.description"

          /> -->
        </TheCard>
        <div class="flex flex-1 gap-4">
          <TheCard class="flex-1 flex flex-col gap-4">
            <div class="text-gray-900 text-lg font-semibold">
              Категория практики
            </div>
            <div>
              <CategorySelector
                @update-selected-categories="updateCategories"
                :course_id="id"
                :use-checkbox="false"
              />
            </div>
          </TheCard>
          <TheCard class="flex-1 flex flex-col gap-4">
            <div class="text-gray-900 text-lg font-semibold">
              Максимальный балл
            </div>
            <div
              class="max-score flex-1 flex items-center px-5 py-1 text-gray-500"
            >
              {{ maxScore }}
            </div></TheCard
          >
        </div>
        <template v-if="currentPage === 1 && type === 'quiz'">
          <TheCard>
            <input
              class="border-none text-lg leading-8 font-semibold text-gray-900 shadow-none w-full focus:outline-none"
              :placeholder="$t('quiz_name')"
              v-model="form.name"
            />
            <!-- <input
                class="border-none text-base leading-6 font-normal text-gray-900 shadow-none w-full  focus:outline-none"
                :placeholder="$t('quiz_name')"
                v-model="form.description"
            /> -->
          </TheCard>
          <!-- <TheCard>
            <div class="text-lg leading-8 font-semibold text-gray-900 ">
              <span>{{$t('quiz_timer_enabled')}}</span>
              <div class="w-fit mt-4">
              <Switch v-model="form.is_timer_enabled" :class="[form.is_timer_enabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                <span class="sr-only">
                  {{$t('quiz_timer_enabled')}}
                </span>
                <span aria-hidden="true" :class="[form.is_timer_enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
              </Switch>
              </div>
            </div>
            <label v-if="form.is_timer_enabled" class="mt-4 ">
              <div class="text-sm leading-5 font-medium text-gray-700 mb-3">{{$t('timer_duration')}}</div>
              <TheTimepicker v-model="form.duration"/>
            </label>
          </TheCard> -->
        </template>
        <!-- <TheCard v-if="resource">
          <component v-for="content in resource.contents"
            :key="content._key"
            :type="content.type"
            :is="`${content.type}-content-block`"
            :_key="content._key"
            :content="content.content"
            @remove="removeResource"
            @contentChange="e=>setContent(content,e)"
          />
        </TheCard> -->
        <draggable
          :list="contents"
          :group="{ name: 'resources' }"
          itemKey="_key"
          handle=".draggable_icon"
          :move="checkMove"
        >
          <template #item="{ element }">
            <component
              :key="element.key"
              :type="element.type"
              :is="`${element.type}-content-block`"
              :_key="element._key || element.id"
              :content="element.content"
              :available_answers="
                element.available_answers ? element.available_answers : []
              "
              :attachments="element.attachments"
              :criteria="element.criteria"
              :threshold="element.threshold"
              :peer_assessment="element.peer_assessment"
              :graders_count="element.graders_count"
              :is_threshold="element.is_threshold"
              :is_valid_threshold="element.is_valid_threshold"
              :is_number_graders="element.is_number_graders"
              v-model="element.id"
              @remove="removeResource"
              @contentChange="(e) => setContent(element, e)"
              @fileUpload="handleFileUpload(element, $event)"
              @removeAvailableAnswer="
                (id, is_id) => handleRemoveAnswer(element, id, is_id)
              "
              @addCriterion="addCriterion(element)"
              @removeCriterion="(index) => removeCriterion(element, index)"
              @peerAssessmentClicked="
                (val) => handlePeerAssessment(element, val)
              "
              @thresholdChange="(t) => (element.threshold = t)"
              @gradersCountChanged="(c) => (element.graders_count = c)"
            />
          </template>
        </draggable>
        <draggable
          :list="[]"
          @add="getInfo"
          :group="{ name: 'new_item', pull: 'clone', put: true }"
          :itemKey="id"
          ref="draggable_place"
          id="draggable_place"
          class="bg-white list-group rounded-xl p-6 mb-4 min-w-fit border-indigo-500 border-dashed border-2 flex place-content-center text-indigo-600 text-center"
        >
          <template #item="{ element }">
            <div class="hidden">
              {{ element.label }}
            </div>
          </template>
        </draggable>
      </div>
      <div class="w-2/5 pl-4">
        <ResourceTypeSelector
          :resource_type="form.type"
          class="sticky top-10"
        />
      </div>
    </div>
    <div class="fixed bottom-0 right-0 left-0 bg-white px-8">
      <div class="flex w-full place-content-between place-items-center">
        <TheButton bg="secondary" @click="addPage" class="flex-shrink-0">
          {{ $t("add_page") }}
        </TheButton>
        <TheResourcesPageFooterPageChanger
          :active-page="currentPage"
          :pagesAmount="pagesAmount"
          @setActivePageIndex="(n) => setActivePageIndex(n)"
        />
        <div class="inline-flex flex-shrink-0">
          <!-- <router-link to="/resources/preview">
            <TheButton bg="outline" class="mr-4">
              {{$t('preview')}}
            </TheButton>
          </router-link> -->

          <TheButton bg="primary" @click="submit">
            {{ $t("save") }}
          </TheButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheCard from "@/components/TheCard/TheCard";
import InputText from "@/components/InputText";
import ResourceTypeSelector from "@/components/Resources/ResourceTypeSelector";
import Draggable from "vuedraggable";
import ResourceFormCard from "@/views/resources/Form/components/ContentBlockForm";
import TextContentBlock from "@/views/resources/Form/components/TextContentBlock";
import ImageContentBlock from "@/views/resources/Form/components/ImageContentBlock";
import AudioContentBlock from "@/views/resources/Form/components/AudioContentBlock";
import YoutubeContentBlock from "@/views/resources/Form/components/YoutubeContentBlock";
import ImageContent from "@/components/Resources/ResourceBlock/ImageContent.vue";
import VideoContentBlock from "@/views/resources/Form/components/VideoContentBlock";
import MultipleChoiceQuestion from "@/views/resources/Form/components/MultipleChoiceQuestion";
import PresentationContentBlock from "@/views/resources/Form/components/PresentationContentBlock";
import FileSubmissionQuestion from "@/views/resources/Form/components/FileSubmissionQuestion";
import MultipleAnswersQuestion from "@/views/resources/Form/components/MultipleAnswersQuestion";
import AudioQuestion from "@/views/resources/Form/components/AudioQuestion";
import ImageQuestion from "@/views/resources/Form/components/ImageQuestion";
import { mapFields } from "vuex-map-fields";
import TheButton from "@/components/Button/Button";
import TheResourcesPageFooterPageChanger from "@/components/ThePageFooter/TheResourcesPageFooterPageChanger";
import { Switch } from "@headlessui/vue";
import TheTimepicker from "@/components/TheTimepicker";
import { mapActions } from "vuex";
import {
  GET_RESOURCE_FOR_EDIT,
  SAVE_RESOURCE,
  CREATE_RESOURCE,
  UPLOAD_ATTACHMENT,
  UPDATE_CONTENT,
} from "@/store/resources";
import CategorySelector from "@/components/Selectors/CategorySelector.vue";

export default {
  components: {
    CategorySelector,
    TheTimepicker,
    TheResourcesPageFooterPageChanger,
    TheButton,
    ResourceFormCard,
    Switch,
    ResourceTypeSelector,
    InputText,
    TheCard,
    Draggable,
    "text-content-block": TextContentBlock,
    "content-content-block": TextContentBlock,
    "image-content-block": ImageContentBlock,
    "audio-content-block": AudioContentBlock,
    "video-content-block": VideoContentBlock,
    "presentation-content-block": PresentationContentBlock,
    "youtube_video-content-block": YoutubeContentBlock,
    "image-table-content-block": ImageContent,
    "multiple_choice_question-content-block": MultipleChoiceQuestion,
    "multiple_answers_question-content-block": MultipleAnswersQuestion,
    "file_submission_question-content-block": FileSubmissionQuestion,
    "audio_question-content-block": AudioQuestion,
    "image_question-content-block": ImageQuestion,
  },
  data: () => ({
    newItem: [],
    category: null,
    id: null,
    maxScore: 0,
  }),

  methods: {
    ...mapActions([
      GET_RESOURCE_FOR_EDIT,
      SAVE_RESOURCE,
      CREATE_RESOURCE,
      UPLOAD_ATTACHMENT,
      UPDATE_CONTENT,
    ]),
    updateCategories(categories) {
      this.category = categories.find((c) => c.selected);
    },
    generateRandomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
    checkMove(evt) {
      const newIdx = evt.draggedContext.futureIndex;
      const oldIdx = evt.draggedContext.index;
      this.contents.map((el) => {
        if (el._key) {
          if (el._key !== evt.draggedContext.element._key) {
            if (el.order - 1 === newIdx) {
              el.order = oldIdx + 1;
            }
          } else {
            el.order = newIdx + 1;
          }
        } else {
          if (el.id !== evt.draggedContext.element.id) {
            if (el.order - 1 === newIdx) {
              el.order = oldIdx + 1;
            }
          } else {
            el.order = newIdx + 1;
          }
        }
      });
    },
    async getInfo(event) {
      const elementToAddId = event.item._underlying_vm_.id;
      const initialData = event.item._underlying_vm_.initial_data ?? {};
      do {
        initialData._key = this.generateRandomKey();
        var index = this.contents.findIndex(
          (el) => el._key === initialData._key
        );
      } while (index > -1);
      const new_resource = {
        type: elementToAddId,
        ...initialData,
        order: this.contents.length + 1,
      };
      this.addResource(new_resource);
      document.body.scrollTop = document.body.scrollHeight;
      this.newItem.pop();
      this.createQuestion(new_resource);
    },
    async createQuestion(obj) {
      // const key = obj._key
      if (this.form.id) {
        obj.resource_id = this.form.id;
      }
      obj.resource_type = this.type;
      obj.resource_name = this.form.name;
      obj.chapter_id = this.chapter_id;
      obj.page = this.currentPage;
      obj.criteria = [];
      obj.threshold = 0;
      obj.graders_count = 0;
      obj.is_threshold = false;
      obj.is_valid_threshold = false;
      obj.is_number_graders = true;
      obj.peer_assessment = false;
      const result = await this.CREATE_RESOURCE(obj);
      this.form.id = result.data.resource_id;
      const idx = this.form.pages[this.currentPage - 1].contents.indexOf(obj);
      // obj.id = result.data.id
      this.form.pages[this.currentPage - 1].contents[idx].id = result.data.id;
    },
    addPage() {
      // if(this.$route.query.id){
      //   this.setActivePageIndex(this.paginator.max_page+1)
      //   // this.GET_RESOURCE_FOR_EDIT({id:this.$route.query.id})
      // } else {
      this.pages.push({ contents: [] });
      this.setActivePageIndex(this.form.current_page + 1);
      // }
    },
    async submit() {
      var validation = true;
      for (var p of this.pages) {
        for (var c of p.contents) {
          if (c.type === "file_submission_question") {
            c.is_threshold = c.threshold.length == 0;
            c.is_valid_threshold =
              c.threshold >
              c.criteria.reduce((a, b) => {
                return parseInt(a) + parseInt(b.weight);
              }, 0);
            if (c.peer_assessment) {
              c.is_number_graders = !(
                c.graders_count === "" || c.graders_count === "0"
              );
            } else {
              c.is_number_graders = true;
            }
            for (var cr of c.criteria) {
              cr.is_empty_description = cr.description.length == 0;
              cr.is_empty_weight = cr.weight.length == 0;
              if (cr.is_empty_description || cr.is_empty_weight) {
                validation = false;
              }
            }
            if (
              c.is_threshold ||
              c.is_valid_threshold ||
              !c.is_number_graders
            ) {
              validation = false;
            }
          }
        }
      }
      if (!validation) {
        return;
      }
      this.form.chapter = this.chapter_id;
      this.form.type = this.$route.query.type
        ? this.$route.query.type
        : this.resource.type;
      await this.SAVE_RESOURCE(this.form);
      history.back();
    },
    addResource(resourceInitialData) {
      this.pages[this.currentPage - 1].contents.push(resourceInitialData);
    },
    setActivePageIndex(num) {
      if (this.resource.pages && this.resource.pages.length >= num) {
        this.form.pages[num - 1].contents = this.resource.pages[
          num - 1
        ].contents.map((el) => {
          el.id = el.id.toString();
          if (el.type === "audio" || el.type === "video") {
            el.content = el.image;
          }
          if (el.type === "multiple_choice" || el.type === "multiple_answers") {
            el.type += "_question";
          }
          if (
            el.type.includes("multiple_choice") ||
            el.type === "audio" ||
            el.type === "video" ||
            el.type.includes("multiple_answers")
          ) {
            if (el.correct_answer || el.correct_answers) {
              el.available_answers.forEach((ans) => {
                ans.is_selected = false;
              });
              let result = el.available_answers.filter(
                (element) =>
                  (el.correct_answer &&
                    (element._key == el.correct_answer ||
                      element.id == el.correct_answer)) ||
                  (el.correct_answers &&
                    (el.correct_answers.includes(element._key) ||
                      el.correct_answers.includes(element.id)))
              );
              if (result) {
                result.map((r) => (r.is_selected = true));
              }
            }
          }
          return el;
        });
      } else {
        this.form.pages[this.currentPage - 1].contents = this.form.pages[
          this.currentPage - 1
        ].contents.map((el) => {
          el.id = el.id.toString();
          if (el.type === "audio" || el.type === "video") {
            el.content = el.image;
          }
          if (
            el.type.includes("multiple_choice") ||
            el.type === "audio" ||
            el.type === "video" ||
            el.type.includes("multiple_answers")
          ) {
            let result = el.available_answers.filter(
              (element) =>
                (el.correct_answer &&
                  (element._key == el.correct_answer ||
                    element.id == el.correct_answer)) ||
                (el.correct_answers &&
                  (el.correct_answers.includes(element._key) ||
                    el.correct_answers.includes(element.id)))
            );
            el.available_answers.forEach((ans) => {
              ans.is_selected = false;
            });
            if (result) {
              result.map((r) => (r.is_selected = true));
            }
          }
          return el;
        });
      }
      this.currentPage = num;
    },
    removeResource(_key) {
      this.contents = this.contents.filter((content) => content._key !== _key);
    },
    async setContent(element, e) {
      if (element.type === "youtube_video") {
        element.url = e;
      } else if (element.type === "multiple_choice_question") {
        if (e.content) {
          element.content = e.content;
        }
        element.available_answers = e.available_answers;
        element.correct_answer = e.correct_answer;
      } else if (element.type === "multiple_answers_question") {
        if (e.content) {
          element.content = e.content;
        }
        element.available_answers = e.available_answers;
        element.correct_answers = e.correct_answers;
      } else if (element.type === "audio_question") {
        element.content = e.content;
        element.available_answers = e.available_answers;
        element.src = e.src;
      } else if (element.type === "image_question") {
        element.content = e.content;
        element.available_answers = e.available_answers;
        element.multiple_answers_available = e.multiple_answers_available;
      } else if (element.type === "file_submission") {
        element.content = e.content;
        // element.available_answers = e.available_answers
      } else if (
        element.type === "image" ||
        element.type === "audio" ||
        element.type === "video" ||
        element.type === "presentation"
      ) {
        await this.$store.dispatch(UPDATE_CONTENT, {
          file: e,
          id: element.id,
          type: element.type,
        });
        element.content = e;
      } else {
        element.content = e;
      }
    },
    handleRemoveAnswer(element, id, is_id) {
      if (is_id) {
        element.available_answers = element.available_answers.filter(
          (e) => e.id !== id
        );
      } else {
        element.available_answers = element.available_answers.filter(
          (e) => e._key !== id
        );
      }
    },
    addCriterion(element) {
      console.log(element);
      element.criteria.push({
        description: "",
        weight: 0,
      });
    },
    removeCriterion(element, index) {
      element.criteria.splice(index, 1);
    },
    handlePeerAssessment(element, val) {
      element.peer_assessment = val;
    },
  },
  watch: {
    category(newVal) {
      if (newVal !== null) {
        this.maxScore = newVal?.weight;
      } else {
        this.maxScore = 0;
      }
    },
  },
  computed: {
    ...mapFields({
      form: "resources.form",
      currentPage: "resources.form.current_page",
      resource: "resources.resource",
      paginator: "resources.paginator",
    }),
    pages() {
      return this.form.pages;
    },

    type: {
      get() {
        return this.form.type || "lesson";
      },
      set(val) {
        this.form.type = val;
      },
    },
    chapter_id: {
      get() {
        return this.$route.query.chapter_id || null;
      },
      set(val) {
        this.form.chapter_id = val;
      },
    },
    contents() {
      return this.pages[this.currentPage - 1].contents;
    },
    pagesAmount() {
      // if(this.$route.query.id){
      //   return this.paginator.max_page
      // } else {
      return this.pages.length;
      // }
    },
    title() {
      if (this.resource.title) {
        return this.resource.title;
      } else {
        return this.form.name;
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  async mounted() {
    const el = this.$refs.draggable_place.$el;
    window.el = el;
    el.innerHTML = this.$t("drag_elements_from_sidebar_for_creation");
    this.chapter_id = this.$route.query.chapter_id;
    if (this.$route.query.type) {
      // this.type = this.$route.query.type
      this.form = {
        pages: [
          {
            contents: [],
          },
        ],
        is_timer_enabled: false,
        name: "",
        description: "",
        current_page: 1,
        duration: "",
        type: this.$route.query.type,
      };
    } else {
      await this.GET_RESOURCE_FOR_EDIT({ id: this.$route.query.id });
      console.log(this.resource);
      this.type = this.resource.type;
      this.form.name = this.resource.title;
      this.form.pages = this.resource.pages;
      this.form.pages[this.currentPage - 1].contents = this.resource.pages[
        this.currentPage - 1
      ].contents.map((el) => {
        el.id = el.id.toString();
        if (el.type === "audio" || el.type === "video") {
          el.content = el.image;
        }
        if (el.type === "multiple_choice" || el.type === "multiple_answers") {
          el.type += "_question";
          // el.content={
          //   content: el.content,
          //   available_answers: el.available_answers
          // }
        }
        return el;
      });
      this.form.id = this.$route.query.id;
    }
  },
};
</script>
<style>
#draggable_place > div {
  display: none;
}

.max-score {
  border: 1px solid #d1d5db;
  border-radius: 6px;
}
</style>
