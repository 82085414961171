import { mapActions } from "vuex";
import {
  CREATE_ATTENDANCE_RECORD,
  DOWNLOAD_ATTENDANCE,
  GET_ATTENDANCE_RECORDS,
  GET_STUDENT_ATTENDANCE_RECORDS,
} from "@/store/attendance";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions([
      CREATE_ATTENDANCE_RECORD,
      GET_ATTENDANCE_RECORDS,
      DOWNLOAD_ATTENDANCE,
      GET_STUDENT_ATTENDANCE_RECORDS,
    ]),
    formatDateToBackend(newDate) {
      return (
        newDate.getFullYear() +
        "-" +
        String(newDate.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(newDate.getDate()).padStart(2, "0")
      );
    },
    async createAttendanceRecord(event) {
      event.stopPropagation();
      const formattedDate = this.formatDate(new Date());
      this.savedText = "Сохраняем...";
      await this[CREATE_ATTENDANCE_RECORD]({
        course: this.group_id,
        date: formattedDate,
        students: this.students.map((st) => ({
          user: st.id,
          attended: st.attended,
        })),
      });
      this.savedText = "Сохранено";
      setTimeout(() => {
        this.savedText = "Сохранить данные";
      }, 3000);
    },
    async getAttendanceRecords() {
      this.loading = true;
      const data = await this[GET_ATTENDANCE_RECORDS]({
        course: this.group_id,
        date: this.formatDate(this.date),
      });

      if (data?.students) {
        this.students = this.students.map((st) => {
          const attendanceRecord = data.students.find((u) => u.user === st.id);
          return { ...st, attended: !!attendanceRecord?.attended };
        });
      } else {
        this.students = this.students.map((st) => {
          return { ...st, attended: null };
        });
      }
      this.loading = false;
    },
    async downloadAttendance(event) {
      event.stopPropagation();
      const data = await this[DOWNLOAD_ATTENDANCE]({
        course: this.group_id,
        date: this.date,
      });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attendance.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async getStudentAttendanceRecords(user) {
      this.modalStudent = await this[GET_STUDENT_ATTENDANCE_RECORDS]({
        course: this.group_id,
        user: user.id,
      });
    },
  },
};
