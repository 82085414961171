<template>
  <div>
    <div class="flex justify-between">
      <h1 class="text-gray-900 text-2xl leading-8 font-semibold mb-10">
        {{ $t("course_content") }}
      </h1>

      <div>
        <TheButton
          id="scroll-up-here-button"
          bg="primary"
          class="mr-4"
          @click="scrollDown"
          v-if="chapters.length >= 3"
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.17375 7.29289C6.56428 6.90237 7.19744 6.90237 7.58796 7.29289L10.8809 10.5858L14.1737 7.29289C14.5643 6.90237 15.1974 6.90237 15.588 7.29289C15.9785 7.68342 15.9785 8.31658 15.588 8.70711L11.588 12.7071C11.1974 13.0976 10.5643 13.0976 10.1737 12.7071L6.17375 8.70711C5.78323 8.31658 5.78323 7.68342 6.17375 7.29289Z"
              fill="white"
            />
          </svg>
        </TheButton>
      </div>
    </div>

    <TheCard
      class="max-w-screen-md"
      style="padding: 0 !important"
      v-for="(chapter, index) in chapters"
      :key="index"
    >
      <div class="p-6 border-b">
        <div class="flex py-5">
          <input
            class="border-none text-lg leading-8 font-semibold text-gray-900 shadow-none w-full focus:outline-none"
            :placeholder="$t('resource_name')"
            v-model="chapter.name"
          />
          <TrashIcon
            class="text-gray-400 h-6"
            @click.prevent="removeChapter(chapter)"
          />
          <span class="mr-2"></span>
          <!-- <input
                class="border-none text-base leading-6 font-normal text-gray-900 shadow-none w-full  focus:outline-none"
                :placeholder="$t('description')"
                v-model="chapter.description"
            /> -->
        </div>
        <hr />
        <div
          class="bg-white rounded-xl p-2 mb-2"
          v-for="resource in chapter.resources"
          :key="`course_resources_${resource.id}`"
        >
          <router-link
            :to="{
              path: '/resources/form',
              query: { id: resource.id, chapter_id: chapter.id },
            }"
          >
            <div class="flex py-5 text-base leading-6 font-medium">
              <span class="flex-1 flex place-items-center">
                <component
                  :is="
                    resource.type === 'lesson'
                      ? 'BookOpenIcon'
                      : 'ClipboardCheckIcon'
                  "
                  class="text-gray-600 h-5 w-5"
                />
                <span class="ml-2">
                  {{ resource.title }}
                </span>
              </span>
              <span class="flex flex-row place-items-center">
                <span class="mr-3">{{ resource.duration }}</span>
              </span>
              <Menu as="div" class="ml-3 relative flex items-center">
                <div>
                  <MenuButton
                    class="pr-3 max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none"
                  >
                    <PencilAltIcon class="w-6 h-6 text-gray-400" />
                  </MenuButton>
                </div>
                <TheTransition>
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <template
                      v-for="type of availableElements"
                      :key="type.value"
                    >
                      <MenuItem class="menu-item">
                        <TheButton
                          class="block text-gray-700 w-full"
                          :class="{
                            'bg-indigo-500 text-white':
                              resource.type === type.value,
                          }"
                          bg="link"
                          size="sm"
                          @click.prevent="
                            alterResourceType(
                              resource.id,
                              type,
                              resource,
                              chapter
                            )
                          "
                        >
                          <component
                            :is="type.icon"
                            class="w-5 h-5 text-gray-400"
                            :class="{
                              'text-white': resource.type === type.value,
                            }"
                          />
                          <div
                            :class="{
                              'text-white': resource.type === type.value,
                            }"
                          >
                            {{ $t(type.value) }}
                          </div>
                          <CheckIcon
                            v-if="resource.type === type.value"
                            class="h-5 w-5 text-white ml-auto font-semibold"
                          />
                        </TheButton>
                      </MenuItem>
                    </template>
                  </MenuItems>
                </TheTransition>
              </Menu>
              <TrashIcon
                class="text-gray-400 h-6"
                @click.prevent="removeResource(resource.id, chapter)"
              />
            </div>
            <hr />
          </router-link>
        </div>
      </div>
      <div class="pt-5 px-6 pb-6 flex flex-row justify-between">
        <div>
          <TheDropdownButton
            placeholder="add_elements"
            @selected="(val) => addResource(val, chapter.id, index)"
            :items="availableElements"
          />
        </div>
        <!-- <div>
            <TheButton bg="primary" v-if="!chapter.id && chapter.show" >
                Сохранить главу
            </TheButton>
          </div> -->
      </div>
    </TheCard>
    <div class="flex justify-between align-middle">
      <TheButton class="mt-5 mb-20" @click="addChapter" bg="secondary">
        <PlusIcon class="flex flex-shrink-0 h-4 mr-2" />
        {{ $t("add_chapter") }}</TheButton
      >
      <div>
        <TheButton
          id="scroll-down-here-button"
          bg="primary"
          class="mr-4"
          @click="scrollUp"
          v-if="chapters.length >= 3"
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.17375 7.29289C6.56428 6.90237 7.19744 6.90237 7.58796 7.29289L10.8809 10.5858L14.1737 7.29289C14.5643 6.90237 15.1974 6.90237 15.588 7.29289C15.9785 7.68342 15.9785 8.31658 15.588 8.70711L11.588 12.7071C11.1974 13.0976 10.5643 13.0976 10.1737 12.7071L6.17375 8.70711C5.78323 8.31658 5.78323 7.68342 6.17375 7.29289Z"
              fill="white"
              transform="rotate(180, 10.5, 10)"
            />
          </svg>
        </TheButton>
      </div>
    </div>

    <div class="fixed bottom-0 right-0 left-0 bg-white py-4 px-8">
      <div class="flex w-full place-content-end place-items-center">
        <div class="inline-flex flex-shrink-0">
          <!-- <router-link to="/resources/preview"> -->
          <!-- <TheButton bg="outline" class="mr-4">
                {{$t('preview')}}
              </TheButton> -->
          <!-- </router-link> -->
          <TheButton bg="secondary" class="mr-4" @click="saveChapters">
            {{ $t("save_as_draft") }}
          </TheButton>
          <router-link :to="`/courses/${id}/edit`">
            <TheButton bg="primary">
              {{ $t("publish_course") }}
            </TheButton>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DELETE_RESOURCE,
  GET_RESOURCES,
  SAVE_CHAPTERS,
  DELETE_CHAPTER,
  ALTER_RESOURCE_TYPE,
} from "@/store/resources";
import { mapActions } from "vuex";
import { GET_COURSE } from "@/store/courses";
import { mapFields } from "vuex-map-fields";
import TheCard from "@/components/TheCard/TheCard";
import TheDropdownButton from "@/components/Button/TheDropdownButton";
import TheButton from "@/components/Button/Button";
import {
  CheckIcon,
  PencilAltIcon,
  PencilIcon,
  PlusIcon,
} from "@heroicons/vue/solid";
import {
  BookOpenIcon,
  ClipboardCheckIcon,
  TrashIcon,
} from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import TheTransition from "@/components/TheTransition";
import Button from "@/components/Button/Button.vue";

export default {
  components: {
    Button,
    TheButton,
    TheDropdownButton,
    TheCard,
    PlusIcon,
    BookOpenIcon,
    ClipboardCheckIcon,
    TrashIcon,
    Menu,
    MenuItems,
    MenuItem,
    MenuButton,
    TheTransition,
    PencilAltIcon,
    PencilIcon,
    CheckIcon,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    availableElements: [
      { label: "lesson", value: "lesson", icon: BookOpenIcon },
      { label: "practice", value: "practice", icon: ClipboardCheckIcon },
      { label: "quiz", value: "quiz", icon: PencilIcon },
      // {label:'webinar',value:'webinar'},
    ],
    chapters: [{ name: "", resources: [], order: 1 }],
  }),
  computed: {
    ...mapFields(["resources.resources"]),
  },
  // mounted() {
  //   // This code will run after the component is mounted
  //   this.scrollDown();
  // },
  methods: {
    ...mapActions([
      GET_RESOURCES,
      GET_COURSE,
      DELETE_RESOURCE,
      SAVE_CHAPTERS,
      DELETE_CHAPTER,
      ALTER_RESOURCE_TYPE,
    ]),

    scrollDown() {
      this.$nextTick(() => {
        const scrollDownButton = document.getElementById(
          "scroll-down-here-button"
        );

        if (scrollDownButton) {
          const buttonOffsetTop = scrollDownButton.offsetTop;

          window.scrollTo({
            top: buttonOffsetTop,
            behavior: "smooth",
          });
        }
      });
    },
    scrollUp() {
      this.$nextTick(() => {
        const scrollUpButton = document.getElementById("scroll-up-here-button");

        if (scrollUpButton) {
          const buttonOffsetTop = scrollUpButton.offsetTop;

          window.scrollTo({
            top: buttonOffsetTop,
            behavior: "smooth",
          });
        }
      });
    },

    addChapter() {
      this.chapters.push({ name: "", description: "", show: true });
    },
    async addResource(value, chapter_id, index) {
      await this.saveChapters();
      chapter_id = this.chapters[index].id;
      if (chapter_id) {
        this.$router.push({
          path: "/resources/form",
          query: { chapter_id: chapter_id, type: value, id: this.id },
        });
      }
    },
    async saveChapters() {
      this.chapters = this.chapters.map((el, idx) => {
        el.order = idx + 1;
        return el;
      });
      const result = await this.SAVE_CHAPTERS({
        chapters: this.chapters,
        course: this.id,
      });
      this.chapters = result.data;
    },
    removeResource(id, chapter) {
      this.DELETE_RESOURCE(id);
      const index_obj = chapter.resources.findIndex((obj) => {
        return obj.id === id;
      });
      chapter.resources.splice(index_obj, 1);
    },
    async alterResourceType(id, newType, resource, chapter) {
      await this[ALTER_RESOURCE_TYPE]({ id, type: newType.value });
      this.$router.push(
        `/resources/form?id=${resource.id}&chapter_id${chapter.id}`
      );
    },
    removeChapter(chapter) {
      // this.DELETE_RESOURCE(id)
      // const index_obj = chapter.resources.findIndex((obj) => {
      //   return obj.id === id
      // })
      // chapter.resources.splice(index_obj, 1)
      if (chapter.id) {
        this.DELETE_CHAPTER(chapter.id);
      }
      this.chapters = this.chapters.filter((ch) => ch !== chapter);
    },
  },
  async created() {
    await this.GET_RESOURCES({ course_id: this.id });
    this.GET_COURSE(this.id);
    this.chapters = this.resources;
  },
};
</script>

<style lang="postcss">
.menu-item {
  @apply justify-start gap-3;
}
</style>
