<template>
  <TheSelect
    :label="label"
    v-model="localValue"
    :options="options"
    :optionComponentName="optionComponentName"
    :multiple="multiple"
    :placeholder="placeholder"
  />
</template>

<script>
import TheSelect from "@/components/Selectors/TheSelect";
import TheSelectMixin from "@/components/Selectors/TheSelectMixin";
import { GET_COURSE_GROUPS } from "@/store/users";
import { GET_ATTENDANCE_COURSE_GROUPS } from "@/store/attendance";

export default {
  components: { TheSelect },
  mixins: [TheSelectMixin],
  props: {
    course_id: Number,
    isAttendance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groups: [],
      group_id: null,
      id: null,
    };
  },

  async created() {
    await this.fetchGroups();
  },
  watch: {
    localValue(newVal) {
      const group = this.isAttendance
        ? this.groups.find((gr) => gr.id.toString() === newVal.toString())
        : this.groups.find(
            (gr) => gr.group_id.toString() === newVal.toString()
          );
      this.$emit("updateGroupName", group?.group_number);
    },
    async course_id(newVal) {
      if (newVal !== null) {
        await this.fetchGroups(newVal);
      } else {
        this.options = [...this.initialOptions];
        this.group_id = null;
        this.id = null;
      }
    },
  },
  methods: {
    async fetchGroups(courseId = this.course_id) {
      if (courseId) {
        this.options = await this.$store.dispatch(
          this.isAttendance ? GET_ATTENDANCE_COURSE_GROUPS : GET_COURSE_GROUPS,
          { course: courseId }
        );
        this.normalizeOptions();
        this.groups = [...this.options];
        if (this.groups.length === 0) {
          this.group_id = null;
          this.id = null;
        }
      }
    },
    normalizeOptions() {
      this.options = this.options.map((element) => ({
        label: element.group_number
          ? `${element.course_name} ${element.group_number}`
          : element.course_name,
        id: element.course_group || element.id,
        name: element.group_number
          ? `${element.course_name} ${element.group_number}`
          : element.course_name,
        group_id: element.group_id || element.id,
      }));
    },
  },
};
</script>
